<template>
  <div>

    <semi-form-header title="Админка" noBorder>
      Админка <small class="text-muted">разделы</small>
    </semi-form-header>

    <!-- <div class="mb-4">
      <div class="form-group">
        <input
          type="text"
          class="form-control search-input"
          placeholder="Введите название раздела"
          ref="search_box">
      </div>
    </div> -->

    <table class="table table-hover">
      <tbody>
        <router-link
          tag="tr"
          :to="section.to"
          v-for="(section, index) in sections"
          :key="index"
          class="table-row"
        >
          <td>{{ section.title }}</td>
        </router-link>
      </tbody>
    </table>

  </div>
</template>

<script>
import SemiFormHeader from '@/components/admin/FormHeader'

export default {
  data () {
    return {
      sections: [
        {
          title: 'Конфиги',
          to: { name: 'adminConfigsList' }
        }
      ]
    }
  },
  components: {
    SemiFormHeader
  },
  mounted () {
    // this.$refs.search_box.focus()
  }
}
</script>

<style lang="scss" scoped>
.table-row {
  cursor: pointer;
}
</style>
